import React, {Component, Fragment} from "react";
import styled from "styled-components";

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Items extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.label !== undefined){
      this.state = this.props.label;
      this.state.allowdisp = true;
      this.state.orientation = "Portrait";
      this.state.total_displayed_value = 0;
      if(this.props.orientation !== undefined){
        this.state.orientation = this.props.orientation;
      }
      if(this.props.total_displayed_value !== undefined){
        this.state.total_displayed_value = this.props.total_displayed_value;
      }
    } else {
      this.state = {items: [], columns: [], allowdisp: true, orientation: "Portrait", total_displayed_value: 0};
    }
  }
  
  convertDate(datestr){
    let date_start = new Date(datestr.substr(0, 10));
    
    let start_year = date_start.getFullYear();
    let start_month = date_start.getMonth();
    let start_date = date_start.getDate();
    
    let returnstr = start_date.toString().padStart(2, "0") + " " + monthNames[start_month] + " " + start_year;
    
    return returnstr;
  }
  
  renderItems(){
    let i = 0;
    let disp = this.state.items.map((dt) => {
      let no = this.state.startidx + i;
      i++;
      
      let stock = dt.up_stock.length > 0 ? dt.up_stock[0].stock : 0;
      
      let columns = this.state.columns.map((dtx) => {
        if(dtx == "No"){
          return (<TableCol className="tno">{no}</TableCol>)
        } else if(dtx == "SKU"){
          return (<TableCol className="tsku">{dt.variant_sku}</TableCol>)
        } else if(dtx == "Name"){
          return (<TableCol className="tname">{dt.variant_name}</TableCol>)
        } else if(dtx == "Sale Price"){
          return (<TableCol className="align-right tprice">{numberFormat(dt.variant_price > 0 ? dt.variant_price.toFixed(2) : (dt.variant_capital_price * 1.25).toFixed(2))}</TableCol>)
        } else if(dtx == "Latest Purchase Price"){
          return (<TableCol className="align-right tprice">{numberFormat(dt.variant_capital_price.toFixed(2))}</TableCol>)
        } else if(dtx == "Average Purchase Price"){
          return (<TableCol className="align-right tprice">{numberFormat((dt.variant_average_price > 0 ? dt.variant_average_price :  dt.variant_capital_price).toFixed(2))}</TableCol>)
        } else if(dtx == "Stock"){
          return (<TableCol className="align-center tqty">{stock.toFixed(2).replace(/[.,]00$/, "")}</TableCol>)
        } else if(dtx == "Unit"){
          return (<TableCol className="align-center tqty">{dt.up_item.up_unit !== null ? dt.up_item.up_unit.unit : ""}</TableCol>)
        } else if(dtx == "SBU"){
          return (<><TableCol className="align-center tqty">{dt.up_item.up_unit2 !== null ? dt.up_item.up_unit2.unit : ""}</TableCol>
          <TableCol className="align-center tqty">{dt.unit_conversion}</TableCol></>)
        } else if(dtx == "LBU"){
          return (<><TableCol className="align-center tqty">{dt.up_item.up_unit3 !== null ? dt.up_item.up_unit3.unit : ""}</TableCol>
            <TableCol className="align-center tqty">{dt.unit_conversion2}</TableCol></>)
        } else if(dtx == "Supplier"){
          return (<TableCol className="align-left">{dt.supplier_name}</TableCol>)
        }
        
        
      });
      
      return (
        <TableRow>
          {columns}
        </TableRow>
      )
      
    });
    
    return disp;
  }
  
  renderHeaders(){
    let disp = this.state.columns.map((dtx) => {
        if(dtx == "No"){
          return (<TableHeader>NO</TableHeader>)
        }
        else if(dtx == "Name"){
          return (<TableHeader>NAME</TableHeader>)
        }
        else if(dtx == "SKU"){
          return (<TableHeader>SKU</TableHeader>)
        }
        else if(dtx == "Sale Price"){
          return (<TableHeader>SALE PRICE</TableHeader>)
        }
        else if(dtx == "Latest Purchase Price"){
          return (<TableHeader>LATEST PURCH.</TableHeader>)
        }
        else if(dtx == "Average Purchase Price"){
          return (<TableHeader>AVG PURCH.</TableHeader>)
        }
        else if(dtx == "Stock"){
          return (<TableHeader>STOCK</TableHeader>)
        }
        else if(dtx == "Unit"){
          return (<TableHeader>UNIT</TableHeader>)
        }
        else if(dtx == "SBU"){
          return (<><TableHeader>SBU</TableHeader><TableHeader>CONV</TableHeader></>)
        }
        else if(dtx == "LBU"){
          return (<><TableHeader>LBU</TableHeader><TableHeader>CONV</TableHeader></>)
        }
        else if(dtx == "Supplier"){
          return (<TableHeader>SUPPLIER</TableHeader>)
        }
    });
    
    return disp;
    
    return (
      <TableRow>
        { this.state.columns.includes("No") &&
          <TableHeader>NO</TableHeader>
        }
        { this.state.columns.includes("Name") &&
          <TableHeader>NAME</TableHeader>
        }
        { this.state.columns.includes("Sale Price") &&
          <TableHeader>SALE PRICE</TableHeader>
        }
        { this.state.columns.includes("Purchase Price") &&
          <TableHeader>PURCHASE PRICE</TableHeader>
        }
        { this.state.columns.includes("Stock") &&
          <TableHeader>STOCK</TableHeader>
        }
        { this.state.columns.includes("Unit") &&
          <TableHeader>UNIT</TableHeader>
        }
        { this.state.columns.includes("SBU") &&
          <>
          <TableHeader>SBU</TableHeader>
          <TableHeader>CONV</TableHeader>
          </>
        }
        { this.state.columns.includes("LBU") &&
          <>
          <TableHeader>LBU</TableHeader>
          <TableHeader>CONV</TableHeader>
          </>
        }
        { this.state.columns.includes("Supplier") &&
          <TableHeader>SUPPLIER</TableHeader>
        }
      </TableRow>
    )
  }
  
  render() {
    return (
      <OuterWrap>
        { this.state.orientation == "Portrait" &&
          <Wrapper>
            {this.state.allowdisp &&
            <>
            <TableContents>
              {this.renderHeaders()}
              {this.renderItems()}
            </TableContents>
            <PageFoot>HALAMAN {this.state.page} DARI {this.state.totalpage}</PageFoot>
            </>
            }
          </Wrapper>
        }
        { this.state.orientation == "Landscape" &&
          <WrapperLandscape>
            {this.state.allowdisp &&
            <>
            <TableContents>
              {this.renderHeaders()}
              {this.renderItems()}
            </TableContents>
            <PageFoot>HALAMAN {this.state.page} DARI {this.state.totalpage}</PageFoot>
            </>
            }
          </WrapperLandscape>
        }
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */
  
  /*width: 14cm;*/
  
  width: 20cm;
  height: 29cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/
  
  margin: 0.35cm auto;
  border: 2px solid black;
  
  /* padding:5mm; */
  padding: 30px;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;
  
  /*
  @page {
    size: landscape;
  }*/
`;

const WrapperLandscape = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */
  
  /*width: 14cm;*/
  
  width: 27cm;
  height: 18cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/
  
  margin: 0.35cm auto;
  border: 2px solid black;
  
  /* padding:5mm; */
  padding: 30px;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;
  
  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;
  
  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderText = styled.p`
  text-align: center;
  margin-bottom: 1mm;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const HeaderInvoiceMP = styled.div`
  font-size: 1em;
  text-align: right;
  margin-bottom: 1mm;
`;

const Recipient = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:3mm;
  padding-bottom: 5mm;
`;

const RecipientRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const RecipientLeft = styled.div`
  display:block;
  position:relative;
  width: 13%;
  float:left;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const RecipientRightLarge = styled.div`
  display:block;
  position:relative;
  width: 81%;
  padding-left:5%;
  float:left;
  font-size:1.3em;
  font-weight:bold;
`;

const RecipientRight = styled.div`
  display:block;
  position:relative;
  width: 81%;
  padding-left:5%;
  float:left;
`;

const TextRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:8mm;
`;

const TotalRow = styled .div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom: 2mm;
  
  &.grandtotalrow {
    padding-top:5mm; 
  }
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const TotalLeft = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display:block;
  position:relative;
  width: 25%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;

const TotalRight = styled.div`
  text-align: right;
  display:block;
  position:relative;
  width: 24%;
  padding-right:1%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;
  
const TableContents = styled.table`
  display:table;
  position:relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size:11px;
`;

const TableRow = styled.tr`
  display:table-row;
  position:relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display:table-cell;
  position:relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position:relative;
  padding: 1%;
  vertical-align:top;
  font-size: 1em;
  border: 1px solid black;
  
  &.align-right {
    text-align: right;
  }
`;

// &.tno {
//   width: 4%; 
// }
// &.tname {
//   width: 50%; 
// }
// &.tqty {
//   width: 6%; 
// }
// &.tprice {
//   width: 10%; 
// }
// &.ttotal {
//   width: 25%; 
// }

const TableBoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
  
  &.shippingaddress {
    margin-bottom: 3mm; 
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Shipping = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:5mm;
`;

const Footer = styled.div`
  display: block;
  border-top: 1px dotted #999;
  padding-top: 2mm;
  padding-bottom: 2mm;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  
  padding-top:2mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  >div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

const FooterLeft = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterMid = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterRight = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const PageFoot = styled.div`
  display:block;
  position: absolute;
  bottom: 5mm;
  width:100%;
  text-align:center;
  font-size:1.2em;
`;

export default Items;