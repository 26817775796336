export const SAVE_LOGIN = "SAVE_LOGIN";

export const saveLogin = (id_user, api_token) => {
  return dispatch => {
    dispatch({
      type: SAVE_LOGIN,
      payload: {
        id_user: id_user,
        api_token: api_token,
      }
    });
  };
};