import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import {
  Dashboard,
  Basket,
  Archive,
  Money,
  BarChart,
  Configure,
  Cubes,
  Deliver,
} from "grommet-icons";

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [],
    };
  }

  componentDidMount() {
    let menu = [];

    if (
      this.props.user.data.vars.system !== undefined &&
      this.props.user.data.vars.system === "sinarjaya"
    ) {
      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin"
      ) {
        menu.push({
          title: "Dashboard",
          itemId: "/dashboard",
          elemBefore: () => <Dashboard />,
        });
      }
    
      let subnav = [];      

      if(this.props.user.data.vars.subsystem === 'bch'){
        subnav = [
          {
            title: "Sales Order",
            itemId: "/sales-order",
          },
          {
            title: "Customers",
            itemId: "/customers",
          },
          {
            title: "Couriers",
            itemId: "/couriers",
          },
        ];
      } else {
        subnav = [
          {
            title: "Sales Order",
            itemId: "/sales-order",
          },
          {
            title: "Delivery Order",
            itemId: "/delivery-order",
          },
          {
            title: "Sales Invoice List",
            itemId: "/sales-invoice",
          },
          {
            title: "Sales Order Retur",
            itemId: "/sales-order-retur",
          },
          {
            title: "Salesman Invoice",
            itemId: "/salesman-invoice",
          },
          {
            title: "Sales Report",
            itemId: "/sales-report",
          },
          {
            title: "Sales Report Supplier",
            itemId: "/sales-report-supplier",
          },
          {
            title: "Performance Report",
            itemId: "/performance-report",
          },
          {
            title: "Customers",
            itemId: "/customers",
          },
          {
            title: "Salesman",
            itemId: "/salesman",
          },
          {
            title: "Couriers",
            itemId: "/couriers",
          },
          {
            title: "Zones",
            itemId: "/zones",
          },
        ];
      }
      
      if(this.props.user.data.vars.subsystem !== 'bch'){
        if (this.props.user.data.role === "warehouse") {
          subnav = [
            {
              title: "Delivery Order",
              itemId: "/delivery-order",
            },
          ];
        } else if (this.props.user.data.role === "sales") {
          subnav = [
            {
              title: "Sales Order",
              itemId: "/sales-order",
            },
            {
              title: "Salesman Invoice List",
              itemId: "/salesman-invoice",
            },
          ];
        }
      }

      menu.push({
        title: "Sales",
        itemId: "a",
        elemBefore: () => <Basket />,
        subNav: subnav,
      });

      if(this.props.user.data.vars.subsystem !== 'bch'){
        subnav = [
          {
            title: "Purchase Order",
            itemId: "/purchase-order",
          },
          {
            title: "Purchase Invoice List",
            itemId: "/purchase-invoice",
          },
          {
            title: "Purchase Item List",
            itemId: "/purchase-item-list",
          },
          {
            title: "Purchase Order Retur",
            itemId: "/purchase-order-retur",
          },
          {
            title: "Delivery Order Retur",
            itemId: "/delivery-order-retur",
          },
          {
            title: "Suppliers",
            itemId: "/suppliers",
          },
        ];
      } else {
        subnav = [
          {
            title: "Purchase Order",
            itemId: "/purchase-order",
          },
          {
            title: "Suppliers",
            itemId: "/suppliers",
          },
        ];
      }

      if (this.props.user.data.role === "warehouse") {
        subnav = [
          {
            title: "Purchase Order",
            itemId: "/purchase-order",
          },
          {
            title: "Purchase Item List",
            itemId: "/purchase-item-list",
          },
        ];
      }

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin" ||
        this.props.user.data.role === "warehouse"
      ) {
        menu.push({
          title: "Purchases",
          itemId: "b",
          elemBefore: () => <Deliver />,
          subNav: subnav,
        });
      }

      subnav = [
        {
          title: "Catalog",
          itemId: "/catalog",
        },
        {
          title: "Inventory",
          itemId: "/inventory",
        },
        {
          title: "Categories",
          itemId: "/categories",
        },
        {
          title: "Units",
          itemId: "/units",
        },
        {
          title: "Item Order List",
          itemId: "/item-order-list",
        },
        {
          title: "Delivery Item List",
          itemId: "/item-delivery-list",
        },
        {
          title: "Item Flow List",
          itemId: "/item-list",
        },
      ];

      if (
        this.props.user.data.role === "warehouse" ||
        this.props.user.data.role === "sales"
      ) {
        subnav = [
          {
            title: "Inventory",
            itemId: "/inventory",
          },
        ];
      }
      
      if(this.props.user.data.id_user === 16){
        subnav = [
          {
            title: "Inventory",
            itemId: "/inventory",
          },
          {
            title: "Item Order List",
            itemId: "/item-order-list",
          },
          {
            title: "Delivery Item List",
            itemId: "/item-delivery-list",
          },
        ];
      }
      
      if (
        this.props.user.data.role === "warehouse"
      ) {
        subnav.push(
          {
            title: "Mutasi",
            itemId: "/mutasi",
          },
        );
      }
      
      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin"
      ) {
        subnav.push({
          title: "Warehouse",
          itemId: "/warehouse",
        },
        {
          title: "Mutasi",
          itemId: "/mutasi",
        });
      }

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin" ||
        this.props.user.data.role === "warehouse" ||
        this.props.user.data.role === "sales"
      ) {
        menu.push({
          title: "Items",
          itemId: "c",
          elemBefore: () => <Archive />,
          subNav: subnav,
        });
      }

      subnav = [
        {
          title: "Transactions",
          itemId: "/transactions",
        },
        {
          title: "Commissions",
          itemId: "/commissions",
        },
        {
          title: "Accounts",
          itemId: "/accounts",
        },
        {
          title: "Reports",
          itemId: "/reports",
        },
      ];

      if (this.props.user.data.role === "sales") {
        subnav = [
          {
            title: "Transactions",
            itemId: "/transactions",
          },
        ];
      }

      if (
        this.props.user.data.vars.system === "sinarjaya" &&
        (this.props.user.data.id_user === 14 /*AGUS*/ || this.props.user.data.id_user === 36 /*YESSY*/)
      ) {
        subnav = [
          {
            title: "Transactions",
            itemId: "/transactions?id=22",
          },
        ];
      }

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "sales" ||
        this.props.user.data.id_user === 14 /*AGUS*/ ||
        this.props.user.data.id_user === 44 /*REZITA*/
      ) {
        menu.push({
          title: "Finances",
          itemId: "e",
          elemBefore: () => <Money />,
          subNav: subnav,
        });
      }
      /*
      if (this.props.user.data.role === "owner") {
        menu.push({
          title: "Statistic",
          itemId: "/statistic",
          elemBefore: () => <BarChart />,
        });
      } */

      if (this.props.user.data.role === "owner") {
        menu.push({
          title: "Settings",
          itemId: "/settings",
          elemBefore: () => <Configure />,
        });
      }
    } else if (
      this.props.user.data.vars.system !== undefined &&
      this.props.user.data.vars.system === "pauline"
    ) {
      //START IF SYSTEM === PAULINE

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin"
      ) {
        menu.push({
          title: "Dashboard",
          itemId: "/dashboard",
          elemBefore: () => <Dashboard />,
        });
      }

      let subnav = [
        {
          title: "Sales Order",
          itemId: "/sales-order",
        },
        {
          title: "Customers",
          itemId: "/customers",
        },
      ];

      if (this.props.user.data.role === "owner") {
        subnav.push({
          title: "Couriers",
          itemId: "/couriers",
        });
      }

      menu.push({
        title: "Sales",
        itemId: "a",
        elemBefore: () => <Basket />,
        subNav: subnav,
      });

      subnav = [
        {
          title: "Purchase Order",
          itemId: "/purchase-order",
        },
        {
          title: "Suppliers",
          itemId: "/suppliers",
        },
      ];

      if (
        this.props.user.data.role ===
        "owner" /* || this.props.user.data.role === 'admin'*/
      ) {
        menu.push({
          title: "Purchases",
          itemId: "b",
          elemBefore: () => <Deliver />,
          subNav: subnav,
        });
      }

      subnav = [
        {
          title: "Catalog",
          itemId: "/catalog",
        },
        {
          title: "Inventory",
          itemId: "/inventory",
        },
        {
          title: "Categories",
          itemId: "/categories",
        },
        {
          title: "Units",
          itemId: "/units",
        },
        {
          title: "Colors",
          itemId: "/colors",
        },
        {
          title: "Sizes",
          itemId: "/sizes",
        },
      ];

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin"
      ) {
        menu.push({
          title: "Items",
          itemId: "c",
          elemBefore: () => <Archive />,
          subNav: subnav,
        });
      }

      if (
        this.props.user.data.vars.productions !== undefined &&
        this.props.user.data.vars.productions === "yes"
      ) {
        subnav = [
          {
            title: "Production Batch",
            itemId: "/production-batch",
          },
          {
            title: "Inventory",
            itemId: "/production-inventory",
          },
          {
            title: "Vendors",
            itemId: "/vendors",
          },
        ];

        if (
          this.props.user.data.role === "owner" ||
          this.props.user.data.role === "admin"
        ) {
          menu.push({
            title: "Productions",
            itemId: "d",
            elemBefore: () => <Cubes />,
            subNav: subnav,
          });
        }
      }

      subnav = [
        {
          title: "Transactions",
          itemId: "/transactions",
        },
      ];

      if (this.props.user.data.role === "owner") {
        subnav.push({
          title: "Reports",
          itemId: "/reports",
        });
      }

      if (
        this.props.user.data.role === "owner" ||
        this.props.user.data.role === "admin"
      ) {
        menu.push({
          title: "Finances",
          itemId: "e",
          elemBefore: () => <Money />,
          subNav: subnav,
        });
      }

      //NOT READY YET
      // { /*
      // if(this.props.user.data.role === 'owner'){
      //   menu.push({
      //     title: 'Statistic',
      //     itemId: '/statistic',
      //     elemBefore: () => <BarChart />,
      //   });
      // }

      // if(this.props.user.data.role === 'owner'){
      //   menu.push({
      //     title: 'Settings',
      //     itemId: '/settings',
      //     elemBefore: () => <Configure />,
      //   });
      // }*/ }
    }

    this.setState({ menu: menu });
  }

  render() {
    return (
      <>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId="/dashboard"
          onSelect={({ itemId }) => {
            if (itemId.toString().length > 1) {
              this.props.history.replace(itemId);
            }
          }}
          items={this.state.menu}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: (userdata) => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
