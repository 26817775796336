import React, {Component, Fragment} from "react";
import styled from "styled-components";

import moment from 'moment';

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PrintPurchaseItemList extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.label !== undefined){
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = {allowdisp: false};
    }
  }
  
  renderItems(){
    let prev = 0;
    let disp = this.state.up_detail.map((dt) => {
      let displayinv = prev != dt.po.id_purchase_order ? true : false;
      prev = dt.po.id_purchase_order;
      
      return (
        <TableRow>
          <TableCol className="tpo">{displayinv && (<strong>{dt.po.up_supplier.supplier_name}<br/>{dt.po.no_invoice_po}<br/>{moment(dt.po.purchase_date).format("DD MMM YYYY")}</strong>)}</TableCol>
          {this.state.supplier ? (
            <TableCol className="tname"><strong>{dt.variant_name}</strong></TableCol>
          ) : (
            <TableCol className="tname"><strong>{dt.variant_name}</strong><br/>{dt.sonotes.replace(/\n\n/g, (dt.up_variant.up_item.up_unit !== null ? " " + dt.up_variant.up_item.up_unit.unit : "") + "\n")}{dt.sonotes.length > 0 ? (dt.up_variant.up_item.up_unit !== null ? " " + dt.up_variant.up_item.up_unit.unit : "") : ""}{dt.notes2.length > 0 ? "\n" + dt.notes2 : ""}</TableCol>
          )}
          <TableCol className="align-center tqty">{dt.quantity}</TableCol>
          <TableCol className="align-center tqty">{dt.received_quantity}</TableCol>
          <TableCol className="align-center tqty" style={{color:"red"}}>{dt.quantity - dt.received_quantity}</TableCol>
          <TableCol className="align-center tqty">{dt.up_variant.up_item.up_unit !== null ? dt.up_variant.up_item.up_unit.unit : ''}</TableCol>
        </TableRow>
      )
    });
    
    return disp;
  }
  
  render() {
    return (
      <OuterWrap>
      <Wrapper>
        {this.state.allowdisp &&
        <>
        <Header>
          <HeaderLeft>
            <HeaderLogo src="logo.png" />
          </HeaderLeft>
          <HeaderRight>
            <HeaderInvoiceWrap>
              <HeaderInvoice>List Item Purchase Order Global</HeaderInvoice>
              <HeaderInvoiceNo>&nbsp;</HeaderInvoiceNo>
            </HeaderInvoiceWrap>
          </HeaderRight>
        </Header>
        <Supplier>
          <SupplierRow>
            <SupplierLeft>Print Date </SupplierLeft>
            <SupplierRight>{moment().format("DD MMM YYYY HH:mm:ss")}</SupplierRight>
          </SupplierRow>
          { /*
          <SupplierRow>
            <SupplierLeft>Est. Finish Date </SupplierLeft>
            <SupplierRight>28 September 2022</SupplierRight>
          </SupplierRow>
          */ }
        </Supplier>
        <TableContents>
          <TableRow>
            <TableHeader>Purchase Order</TableHeader>
            <TableHeader>Item Name</TableHeader>
            <TableHeader>Order Qty</TableHeader>
            <TableHeader>Received Qty</TableHeader>
            <TableHeader>Remaining</TableHeader>
            <TableHeader>Unit (RU)</TableHeader>
          </TableRow>
          <TableRow>
            <TableCol /><TableCol /><TableCol /><TableCol />
          </TableRow>
          { this.renderItems() }
        </TableContents>
        </>
        }
      </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */
  
  /*width: 14cm;*/
  
  width: 20cm;
  height: 26.05cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/
  
  margin: 0 auto;
  border: 2px solid black;
  
  /* padding:5mm; */
  padding: 30px;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;
  
  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;
  
  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderSupplierName = styled.div`
  display: block;
  width: 60%;
  margin-left:0.25cm;
  margin-top:0.25cm;
  font-size:1.5em;
  font-weight:bold;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderText = styled.p`
  text-align: center;
  margin-bottom: 1mm;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const HeaderInvoiceMP = styled.div`
  font-size: 1em;
  text-align: right;
  margin-bottom: 1mm;
`;

const Supplier = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:3mm;
  padding-bottom: 5mm;
`;

const SupplierRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const SupplierLeft = styled.div`
  display:block;
  position:relative;
  width: 22%;
  float:left;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const SupplierRight = styled.div`
  display:block;
  position:relative;
  width: 72%;
  padding-left: 5%;
  float: left;
  
  &.boldred {
    font-weight: bold; 
    color: red;
  }
`;

const TextRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:8mm;
`;

const TotalRow = styled .div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom: 2mm;
  
  &.grandtotalrow {
    padding-top:5mm; 
  }
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const TotalLeft = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display:block;
  position:relative;
  width: 25%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;

const TotalRight = styled.div`
  text-align: right;
  display:block;
  position:relative;
  width: 24%;
  padding-right:1%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;
  
const TableContents = styled.table`
  display:table;
  position:relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size:11px;
`;

const TableRow = styled.tr`
  display:table-row;
  position:relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display:table-cell;
  position:relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position:relative;
  padding: 1%;
  vertical-align:top;
  font-size: 1em;
  
  &.align-right {
    text-align: right;
  }
  &.tpo {
    width: 20%; 
  }
  &.tname {
    width: 40%; 
    white-space: pre-wrap;
  }
  &.tqty {
    width: 10%; 
  }
  &.tprice {
    width: 12%; 
  }
  &.ttotal {
    width: 12%; 
  }
  &.tso {
    width: 40%; 
    white-space: pre-wrap;
  }
  
`;

const TableBoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
  
  &.shippingaddress {
    margin-bottom: 3mm; 
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Footer = styled.div`
  display: block;
  border-top: 1px dotted #999;
  padding-top: 2mm;
  padding-bottom: 2mm;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  
  padding-top:2mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  >div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

const FooterLeft = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterMid = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterRight = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

export default PrintPurchaseItemList;