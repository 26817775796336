import React, {Component, Fragment} from "react";
import styled from "styled-components";

import moment from 'moment';

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PrintSalesOrder extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.label !== undefined){
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = {allowdisp: false};
    }
  }
  
  renderItems(){
    let disp = this.state.up_detail.map((dt) => {
      let unitconversion = "";
      let unit = "";
      if(dt.id_variant > 0){
        if(dt.unit_selection == 2){
          unit = dt.up_variant.up_item.up_unit2 !== null ? dt.up_variant.up_item.up_unit2.unit : "";
          let convqty = dt.quantity / (dt.up_variant.unit_conversion > 0 ? dt.up_variant.unit_conversion : 1);
          unitconversion = (<>{convqty.toFixed(2)} {unit}</>);
        }
        else if(dt.unit_selection == 3){
          unit = dt.up_variant.up_item.up_unit3 !== null ? dt.up_variant.up_item.up_unit3.unit : "";
          let convqty = dt.quantity / (dt.up_variant.unit_conversion2 > 0 ? dt.up_variant.unit_conversion2 : 1);
          unitconversion = (<>{convqty.toFixed(2)} {unit}</>)
        }
      }
      
      return (
        <TableRow>
          <TableCol className="tname">{dt.variant_name}</TableCol>
          {dt.id_variant > 0 ? (
            <TableCol className="align-center tqty">{dt.quantity} {dt.up_variant.up_item.up_unit !== null ? dt.up_variant.up_item.up_unit.unit : ""}</TableCol>
          ) : (
            <TableCol className="align-center tqty">{dt.quantity} {dt.unit}</TableCol>
          ) }
          <TableCol className="align-center tqty">
            {/*dt.id_variant > 0 &&
              <>
                {dt.up_variant.up_item.up_unit3 !== null &&
                  <>
                  {parseFloat(dt.quantity / dt.up_variant.unit_conversion2).toFixed(2)} {dt.up_variant.up_item.up_unit3.unit}
                  </>
                }
              </>
            }*/}
            {unitconversion}
          </TableCol>
          <TableCol className="align-right tprice">{numberFormat(dt.price)}</TableCol>
          <TableCol className="align-right ttotal">{numberFormat(dt.price * dt.quantity)}</TableCol>
        </TableRow>
      )
    });
    
    return disp;
  }
  
  render() {
    return (
      <OuterWrap>
      <Wrapper>
        {this.state.allowdisp &&
        <>
        <Header>
          <HeaderLeft>
            <HeaderLogo src="logo.png" />
          </HeaderLeft>
          <HeaderRight>
            <HeaderSupplierName>
              {this.state.up_customer.fullname}
            </HeaderSupplierName>
            <HeaderInvoiceWrap>
              <HeaderInvoice>{"Sales Order" + (this.state.retur !== undefined && this.state.retur !== null && this.state.retur ? " RETUR" : "")}</HeaderInvoice>
              <HeaderInvoiceNo>No: {this.state.no_invoice}</HeaderInvoiceNo>
            </HeaderInvoiceWrap>
          </HeaderRight>
        </Header>
        <Supplier>
          <SupplierRow>
            <SupplierLeft>Order Date </SupplierLeft>
            <SupplierRight>{moment(this.state.order_date).format("DD MMM YYYY")}</SupplierRight>
            <HeaderInvoiceNo>Halaman {this.state.page} dari {this.state.totalpage}</HeaderInvoiceNo>
          </SupplierRow>
          { /*
          <SupplierRow>
            <SupplierLeft>Est. Finish Date </SupplierLeft>
            <SupplierRight>28 September 2022</SupplierRight>
          </SupplierRow>
          */ }
        </Supplier>
        <TableContents>
          <TableRow>
            <TableHeader>ITEM DESCRIPTION</TableHeader>
            <TableHeader>QTY</TableHeader>
            <TableHeader>BULK</TableHeader>
            <TableHeader>PRICE (Rp)</TableHeader>
            <TableHeader>TOTAL (Rp)</TableHeader>
          </TableRow>
          <TableRow>
            <TableCol /><TableCol /><TableCol /><TableCol />
          </TableRow>
          { this.renderItems() }
        </TableContents>
        <Totals>
          <TotalRow className="grandtotalrow">
            <TotalLeft></TotalLeft>
            <TotalMid className="grandtotalcol">{this.state.page == this.state.totalpage ? "GRAND TOTAL" : ""}</TotalMid>
            <TotalRight className="grandtotalcol">{this.state.page == this.state.totalpage ? "IDR " + numberFormat(this.state.gross_total) : ""}</TotalRight>
          </TotalRow>
        </Totals>
        { this.state.up_customer !== null &&
        <Footer>
          <FooterRow>
            <BoldText className="shippingaddress">CUSTOMER DETAIL</BoldText>
            <Supplier>
              <SupplierRow>
                <SupplierLeft>Supplier Name </SupplierLeft>
                <SupplierRight>{this.state.up_customer.fullname}</SupplierRight>
              </SupplierRow>
              <SupplierRow>
                <SupplierLeft>Address </SupplierLeft>
                <SupplierRight>{this.state.up_customer.up_address[0].street}, {this.state.up_customer.city}</SupplierRight>
              </SupplierRow>
              <SupplierRow>
                <SupplierLeft>Phone </SupplierLeft>
                <SupplierRight>{this.state.up_customer.phone}</SupplierRight>
              </SupplierRow>
            </Supplier>
          </FooterRow>
          <FooterRow className="align-center">
            <FooterId>
              <BoldText>CV. Sinar Jaya</BoldText>
              <RegularText> - </RegularText>
              <RegularText>Address: Jl. Simolawang Baru Sekolahan IV No. 5-7, Surabaya</RegularText>
              <RegularText> - </RegularText>
              <RegularText>Phone: (031) 3726999</RegularText>
            </FooterId>
          </FooterRow>
        </Footer>
        }
        </>
        }
      </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */
  
  /*width: 14cm;*/
  
  width: 20cm;
  height: 27cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/
  
  margin: 0 auto;
  border: 2px solid black;
  
  /* padding:5mm; */
  padding: 30px;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;
  
  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;
  
  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderSupplierName = styled.div`
  display: block;
  width: 60%;
  margin-left:0.25cm;
  margin-top:0.25cm;
  font-size:1.5em;
  font-weight:bold;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderText = styled.p`
  text-align: center;
  margin-bottom: 1mm;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const HeaderInvoiceMP = styled.div`
  font-size: 1em;
  text-align: right;
  margin-bottom: 1mm;
`;

const Supplier = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:3mm;
  padding-bottom: 5mm;
`;

const SupplierRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const SupplierLeft = styled.div`
  display:block;
  position:relative;
  width: 22%;
  float:left;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const SupplierRight = styled.div`
  display:block;
  position:relative;
  width: 72%;
  padding-left: 5%;
  float: left;
`;

const TextRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:8mm;
`;

const TotalRow = styled .div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom: 2mm;
  
  &.grandtotalrow {
    padding-top:5mm; 
  }
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const TotalLeft = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display:block;
  position:relative;
  width: 25%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;

const TotalRight = styled.div`
  text-align: right;
  display:block;
  position:relative;
  width: 24%;
  padding-right:1%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;
  
const TableContents = styled.table`
  display:table;
  position:relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size:11px;
`;

const TableRow = styled.tr`
  display:table-row;
  position:relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display:table-cell;
  position:relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position:relative;
  padding: 1%;
  vertical-align:top;
  font-size: 1em;
  
  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 50%; 
  }
  &.tqty {
    width: 10%; 
  }
  &.tprice {
    width: 20%; 
  }
  &.ttotal {
    width: 20%; 
  }
`;

const TableBoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
  
  &.shippingaddress {
    margin-bottom: 3mm; 
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Footer = styled.div`
  display: block;
  border-top: 1px dotted #999;
  padding-top: 2mm;
  padding-bottom: 2mm;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  
  padding-top:2mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  >div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

const FooterLeft = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterMid = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterRight = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

export default PrintSalesOrder;